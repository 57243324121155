
import BusinessLogo from "@/components/business/BusinessLogo"
import OImage from "@/components/base/image/OImage"

import {
    mdiClose,
    mdiDotsHorizontal,
    mdiPencil,
    mdiDelete,
    mdiPlayCircleOutline,
} from "@mdi/js"
import { routeToEditPost } from "@/helpers/routing"

export default {
    name: "PostCard",
    components: {
        OImage,
        Modal: () => import("@/components/base/modal/Modal"),
        BusinessLogo,
    },
    inherit: true,
    filters: {
        maxText: function (value) {
            return value.length > 125 ? value.substring(0, 125) + "..." : value
        },
        likeIcon: function (post) {
            return post.liked_by_auth_user ? "favorite" : "favorite_border"
        },
    },
    props: {
        post: Object,
        managePosts: {
            type: Boolean,
            default: false,
        },
        handleExternal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            mdiClose,
            mdiDotsHorizontal,
            mdiPencil,
            mdiDelete,
            mdiPlayCircleOutline,
            direction: "bottom",
            fab: false,
            fling: false,
            showDeleteDialog: false,
            tabs: null,
            top: true,
            right: true,
            transition: "slide-y-transition",
            snackbarMessage: null,
        }
    },
    computed: {
        mainImage() {
            return this.$getImage({ image: this.post.image, preferedSize: "2" })
        },
    },
    methods: {
        routeToPost() {
            let nextRoute = {
                name: "beitrag-postSlug",
                params: {
                    postSlug: this.post.slug,
                },
            }
            return nextRoute
        },
        displayPost: function () {
            this.$store.dispatch("post/setDisplayedPost", {
                post: this.post,
                displayType: "display",
            })
        },
        deletePost: function () {
            this.$axios.delete("/api/posts/" + this.post.slug).then((r) => {
                if (r.data.success) {
                    this.showDeleteDialog = false
                    this.$store.dispatch(
                        "post/getPostsByBusiness",
                        this.post.author.slug
                    )
                }
            })
        },
        onPostEdit() {
            if (this.handleExternal) {
                this.$emit("edit", this.post)
            } else {
                this.$router.push(routeToEditPost(this.post))
            }
        },
        onPostDelete() {
            if (this.handleExternal) {
                this.$emit("delete", this.post)
            } else {
                this.showDeleteDialog = true
            }
        },
    },
}
